import { AdvertisementType } from './advertisement-type.enum';
import { Batch } from './batch.interface';
import { Company } from './company.interface';
import { InstantWinConfiguration } from './instant-win.class';
import { Product } from './product.class';
import { User } from './user.interface';

export class Advertisement {
  id?: string;
  name?: string;
  url?: string;
  authenticatedUrl: boolean = true;
  detail?: string;
  artwork?: string;
  alt?: string;
  tags: string[] = [];
  batches: Batch[] = [];
  batchIds: string[] = [];
  products: Product[] = [];
  productIds: (string | undefined)[] = [];
  productName?: string;
  activeFrom: string = new Date().toISOString();
  activeUntil: string = new Date(
    new Date().setDate(new Date().getDate() + 14)
  ).toISOString();
  active: boolean = false;
  notes?: string;
  priority: boolean = false;
  company?: Company;
  companyId?: string;
  manufacturedBy?: Company;
  manufacturedById?: string;
  createdBy?: User;
  abAdvertisementId?: string;
  abAdvertisementName?: string;
  deletedAt?: Date;
  state?: AdvertisementState;
  createdAt?: Date;
  lastEditedAt?: Date;
  order?: number;
  margin?: boolean;
  instantWinId?: string;
  instantWin?: InstantWinConfiguration;

  type: AdvertisementType = AdvertisementType.Advertisement;
  objectType: AdvertisementObjectType = AdvertisementObjectType.Image;
}

export enum AdvertisementObjectType {
  Image = 'Image',
  Video = 'Video',
}

export interface MiniAdvertisement {
  id: string;
  type: AdvertisementType;
  name: string;
  instantWin: InstantWinConfiguration;
  artwork?: string;
  priority: boolean;
  state: AdvertisementState;
  active: boolean;
  abAdvertisementId?: string;
}

export enum AdvertisementState {
  Active = 'Active',
  Ready = 'Ready',
  Suspended = 'Suspended',
  Inactive = 'Inactive',
}
